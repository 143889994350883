// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("./../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-instant-loans-in-the-uk-ads-js": () => import("./../src/pages/instant-loans-In-the-uk-ads.js" /* webpackChunkName: "component---src-pages-instant-loans-in-the-uk-ads-js" */)
}

